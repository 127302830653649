type ApplicationDelegate = Promise<{default: () => unknown }>
type PolyfillRequirement = `Some` | `None`
type PolyfillLevel = `Fat` | `Lean`


export default function main(appDelegate: ApplicationDelegate, overridePredicate?: PolyfillLevel | PolyfillRequirement | undefined) {


  const forceNone = overridePredicate === undefined || overridePredicate === "None"

  const forceLean = overridePredicate !== undefined && overridePredicate !== "Fat"


  const checkBrowserTarget = (): Promise<PolyfillRequirement | `Some`> =>
    typeof Object[`hasOwn`] === `function` || forceNone ? Promise.reject(`None`) : Promise.resolve(`Some`)

  const checkForNecessaryPolyfill = (): Promise<PolyfillLevel> =>
    typeof Promise[`any`] === `function` || forceLean
      ? import(`../buildtools/config.make/internals/polyfills/polyfill_lean`).then(_ => `Lean`)
      : import(`../buildtools/config.make/internals/polyfills/polyfill_fat`).then(_ => `Fat`)


  const loadApplication = ()  => appDelegate.then(x => x.default())

  checkBrowserTarget()
    .then(checkForNecessaryPolyfill)
    .then(browserTarget => console.log(`Loaded polyfills: ${browserTarget}`))
    .catch(reason => console.log(`Using polyfills: ${reason}`))
    .finally(loadApplication)
    .finally(() => console.log(`Loaded Application`))

}


